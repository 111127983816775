import styled from 'styled-components'

export const Body = styled.ul`
  display: flex;
  gap: 20px;
`

export const Tab = styled.button<{
  textColor?: string | null
  active: boolean
}>`
  align-items: center;
  color: ${o => o.textColor};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  letter-spacing: 0.65px;
  line-height: 18px;
  position: relative;
  user-select: none;
  padding-bottom: 1px;

  > b {
    font-weight: 700;
  }

  &:hover {
    opacity: 0.9;
  }

  ${o =>
    o.active &&
    `
  border-color: normalColor;
  border-style: solid;
  border-width: 0;
  border-bottom-width: ${o.theme.link.underline.lineWidth};`}

  &[aria-selected='true'] {
    cursor: default;

    &:hover {
      opacity: 1;
    }

    &::after {
      background: currentColor;
    }
  }
`
