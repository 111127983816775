import { FC } from 'react'

import {
  PageTabTileSection as Props,
  PageTabTileSectionTile as TileProps,
} from '../../../../../types/graphql'
import { CenteredTileLayout } from '../../../CenteredTileLayout'
import {
  maxTileWidth,
  minTileWidth,
} from '../../../pages/Collection/sections/CollectionGridSection/DesktopFilterBar/VisualFiltersSection/VisualFilters'

import { PageTabTileSectionTile } from './PageTabTileSectionTile'

const VISIBLE_TILES_DESKTOP = 8
const VISIBLE_TILES_MOBILE = 2
/**
 * PageNavTileSection is a tabbed interface.
 * - https://www.w3.org/TR/wai-aria-practices/#tabpanel
 *
 * - NavPanel aria role='tabpanel'
 * - NavTabButtons role='tablist'
 */
export const PageTabTileSection: FC<Props> = ({
  name,
  layoutVariantDesktop,
  layoutVariantMobile,
  tiles,
  tileVariantDesktop,
  tileVariantMobile,
}) => {
  return (
    <CenteredTileLayout
      layoutVariantDesktop={layoutVariantDesktop}
      layoutVariantMobile={layoutVariantMobile}
      tileGapDesktop={16}
      tileGapMobile={16}
      minTileWidth={minTileWidth}
      maxTileWidth={maxTileWidth}
      tiles={tiles}
      keyFromTile={(o: TileProps, index: number) => `${index}--${o.id}`}
      renderTile={(o: TileProps) => (
        <PageTabTileSectionTile
          {...o}
          name={name}
          tileVariantDesktop={tileVariantDesktop}
          tileVariantMobile={tileVariantMobile}
          visibleTilesDesktop={VISIBLE_TILES_DESKTOP}
          visibleTilesMobile={VISIBLE_TILES_MOBILE}
        />
      )}
      visibleTilesDesktop={VISIBLE_TILES_DESKTOP}
      visibleTilesMobile={VISIBLE_TILES_MOBILE}
      liftedButtons
    />
  )
}
