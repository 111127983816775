'use client'

import { useMemo } from 'react'

import { NostoProductRecommendationsSection } from '../brunswick/components/NostoProductRecommendationsSection'
import { ErrorViewHead, ErrorViewSection } from '../brunswick/components/pages/ErrorView'

import { useTrackPageView } from './_providers/TrackingProvider.client'
import { useTranslation } from './_providers/TranslationProvider.client'

export const NotFoundPage = () => {
  const {
    globals: { canonicalBaseUrl },
    pages: {
      notFound: { canonicalPath: canonical404Path },
    },
  } = useTranslation()

  return (
    <>
      <ErrorViewHead canonicalUrl={`${canonicalBaseUrl.home}${canonical404Path}`} />
      <NotFoundPageContents />
    </>
  )
}

export const NotFoundPageContents = () => {
  useTrackPageView({
    pageViewHandle: 'Not Found',
    pageViewCategory: 'not found',
  })

  const {
    pages: {
      notFound: { image, links, subheading, heading },
    },
  } = useTranslation()

  const orderedLinks = useMemo(() => {
    return [links.shopWomen, links.shopMen]
  }, [links.shopMen, links.shopWomen])

  return (
    <>
      <ErrorViewSection
        heading={heading}
        subheading={subheading}
        imageAlt={image.alt}
        imageSrc={image.src}
        links={orderedLinks}
      />

      <NostoProductRecommendationsSection
        analyticsContext={{ category: 'not-found-page::notfound-nosto-2' }}
        nostoPlacementId='notfound-nosto-2'
      />

      <NostoProductRecommendationsSection
        analyticsContext={{ category: 'not-found-page::notfound-nosto-1' }}
        nostoPlacementId='notfound-nosto-1'
      />
    </>
  )
}
