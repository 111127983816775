import React from 'react'

import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { PageTab } from '../../../../../types/graphql'
import { getPanelIdForPageTab, getTabIdForPageTab } from '../utils'

import { Body, Tab } from './styles'

export const PageTabButtons: React.FC<{
  activeTab: PageTab | null
  ariaLabelledBy?: string
  getOnClickTab: (tab: PageTab) => (event: React.MouseEvent<HTMLElement>) => void
  tabs: PageTab[]
}> = ({ activeTab, ariaLabelledBy, getOnClickTab, tabs }) => {
  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  return (
    <Body aria-labelledby={ariaLabelledBy} role='tablist'>
      {tabs.map(tab => (
        <Tab
          {...trackEvent({
            action: 'click tab',
            category: 'PageTabSection',
            label: tab.text,
            pageSectionIndex,
          })}
          aria-controls={getPanelIdForPageTab(tab)}
          aria-selected={tab.id === activeTab?.id}
          id={getTabIdForPageTab(tab)}
          key={tab.id}
          onClick={getOnClickTab(tab)}
          role='tab'
          textColor={tab.textColor}
          active={tab.id === activeTab?.id}
        >
          <b>{tab.text}</b>
        </Tab>
      ))}
    </Body>
  )
}
